import React from "react";

import {
  GameCard,
  GameDescription,
  GameImage,
  GameLink,
  GameText,
  GameTitle,
  HeroContainer,
} from "./HeroSectioneElements";

import RadicalRacersX1Image from "../../images/radicalRacersX1.png";
import RadicalRacersImage from "../../images/radicalRacers.png";
import MegaManSecretImage from "../../images/megaManSecret.png";
import SquardianaImage from "../../images/squardiana.png";
import BackgroundImage from "../../images/background.jpg";
import FatsoBatImage from "../../images/fatsoBat.png";
import TireballImage from "../../images/tireball.png";

const RadicalRacersX1Link =
  "https://radical-racers-x-1.onrender.com/radicalRacersX1.html";
const RadicalRacersLink =
  "https://radical-racers-x.onrender.com/radicalRacersX.html";
const MegaManSecretLink = "https://megaguy-build.onrender.com/megaGuy.html";
const SquardianaLink = "https://protiana-build.onrender.com/protiana.html";
const FatsoBatLink = "https://fatsobat-build.onrender.com/index.html";
const TireballLink = "https://tireball-build.onrender.com/index.html";

const HeroSection = () => {
  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
  };
  return (
    <HeroContainer style={style}>
      <GameLink href={RadicalRacersX1Link} id="radical-racers-x1">
        <GameDescription>
          <GameTitle>Radical Racers vX.1</GameTitle>
          <GameText>Race like it's Windows 98 SE</GameText>
        </GameDescription>
        <GameImage src={RadicalRacersX1Image} />
      </GameLink>
      <GameLink href={RadicalRacersLink} id="radical-racers">
        <GameDescription>
          <GameTitle>Radical Racers</GameTitle>
          <GameText>Race like it's Windows 98</GameText>
        </GameDescription>
        <GameImage src={RadicalRacersImage} />
      </GameLink>
      <GameLink href={MegaManSecretLink} id="mega-man-secret">
        <GameDescription>
          <GameTitle>Mega Man Secret</GameTitle>
          <GameText>Mega Man tribute game</GameText>
        </GameDescription>
        <GameImage src={MegaManSecretImage} />
      </GameLink>
      <GameLink href={SquardianaLink} id="squardiana">
        <GameDescription>
          <GameTitle>Squardiana Jones</GameTitle>
          <GameText>Swing around in the darkness</GameText>
        </GameDescription>
        <GameImage src={SquardianaImage} />
      </GameLink>
      <GameLink href={FatsoBatLink} id="fatso-bat">
        <GameDescription>
          <GameTitle>Fatso Bat</GameTitle>
          <GameText>Jump slowly in the darkenss</GameText>
        </GameDescription>
        <GameImage src={FatsoBatImage} />
      </GameLink>
      <GameLink href={TireballLink} id="tireball">
        <GameDescription>
          <GameTitle>Tireball</GameTitle>
          <GameText>Watch score increase over a black background</GameText>
        </GameDescription>
        <GameImage src={TireballImage} />
      </GameLink>
    </HeroContainer>
  );
};

export default HeroSection;

import React from "react";

import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";

const SecretProjectLink =
  "https://simons-secret-build.onrender.com/castlevaniaSecret.html";

const Home = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <a href={SecretProjectLink}>π</a>
    </>
  );
};

export default Home;

import styled from "styled-components";

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GameDescription = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 320px;
  background-color: #000000;
  border-style: outset;
  border-width: 8px;
  border-color: grey;
`;

export const GameTitle = styled.h1`
  color: #0af;
  font-size: 48px;
  text-align: center;
  padding-bottom: 32px;
`;

export const GameText = styled.h2`
  color: #ccc;
  font-size: 20px;
  text-align: center;
`;

export const GameLink = styled.a`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;

  &:link {
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const GameImage = styled.img`
  border-style: outset;
  border-color: blue;
  border-width: 2px;
  display: flex;
  height: 250px;
  width: 250px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
